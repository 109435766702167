<template>
  <div class="detail-page">
    <div class="detail-page__left"
         v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
      <div class="admin-edit" @click="editTranslate(['menu_ErrorLogsBackend',])"></div>
      <CardLeftBlock
          :name="$t('menu_ErrorLogsBackend.localization_value.value')"
          :value="'#' + ErrorLog.id"
          :backgroundImage="'customs-info'"
      >
      </CardLeftBlock>
    </div>

    <div class="detail-page__right">
      <CardRightBlock>
        <template slot="header">
          <div class="order-create__head">
            <div class="order-create__head-line">
              <div class="order-create__header-col"
                   v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                <div class="admin-edit" @click="editTranslate(['common_backTo',])"></div>
                <router-link :to="$store.getters.GET_PATHS.errorLogs" class="order-create__head-back">
                  <LinkBack
                      :value="$t('common_backTo.localization_value.value')"
                      @click.native="goRouterBack()"
                  />
                </router-link>
              </div>
            </div>
            <div class="order-create__head-title"
                 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
              {{$t('menu_ErrorLogsBackend.localization_value.value')}}
            </div>
          </div>
        </template>

        <template slot="body">
          <div class="order-create__section">

            <div class="custom-row">
              <div class="custom-col">
                <div class="order-create__section-label section-label"
                     v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                  Context
                </div>
                <div class="custom-row">
                  <div class="order-create__col custom-col">{{ ErrorLog.context }}</div>
                </div>
              </div>
              <div class="custom-col">
                <div class="order-create__section-label section-label"
                     v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                  Extra
                </div>
                <div class="custom-row">
                  <div class="order-create__col custom-col">
                    {{ ErrorLog.extra }}
                  </div>
                </div>
              </div>
              <div class="custom-col">
                <div class="order-create__section-label section-label"
                     v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                  Formatted
                </div>
                <div class="custom-row">
                  <div class="order-create__col custom-col" style="line-height: 25px; font-size: 15px;">
                    {{ ErrorLog.formatted }}
                  </div>
                </div>
              </div>
              <div class="custom-col">
                <div class="order-create__section-label section-label"
                     v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                  Message
                </div>
                <div class="custom-row">
                  <div class="order-create__col custom-col">
                    {{ ErrorLog.message }}
                  </div>
                </div>
              </div>
              <div class="custom-col">
                <div class="order-create__section-label section-label"
                     v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                  Remote addr
                </div>
                <div class="custom-row">
                  <div class="order-create__col custom-col">
                    {{ ErrorLog.remote_addr }}
                  </div>
                </div>
              </div>
              <div class="custom-col">
                <div class="order-create__section-label section-label"
                     v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                  User agent
                </div>
                <div class="custom-row">
                  <div class="order-create__col custom-col">
                    {{ ErrorLog.user_agent }}
                  </div>
                </div>
              </div>
              <div class="custom-col">
                <div class="order-create__section-label section-label"
                     v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                  Record datetime
                </div>
                <div class="custom-row">
                  <div class="order-create__col custom-col">
                    {{ ErrorLog.record_datetime }}
                  </div>
                </div>
              </div>
            </div>

          </div>
        </template>
      </CardRightBlock>

    </div>
  </div>
</template>

<script>
  import CardLeftBlock from "../../../../coreComponents/CardLeftBlock/CardLeftBlock";
  import CardRightBlock from "../../../../coreComponents/CardRightBlock/CardRightBlock";
  import LinkBack from "../../../../UI/buttons/LinkBack/LinkBack";

  export default {
    name: "ErrorLogsShow",

    components:{
      LinkBack,
      CardRightBlock,
      CardLeftBlock
    },

    data() {
      return {
        ErrorLog: {},
      }
    },

    computed: {

    },

    mounted() {
      this.initErrorLogsShow()
    },

    methods: {
      initErrorLogsShow() {
          this.$store.dispatch('getErrorLogsBackend', this.$route.params.id).then((response) => {
            this.ErrorLog = this.getRespData(response)
          })//.catch(error => this.createErrorLog(error))
      },

    }

  }
</script>

<style scoped>

</style>
